<template>
  <AppLayout>
    <router-view></router-view>
  </AppLayout>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({}),
};
</script>
